<template>
  <div class="bg-light p-3" @verForm="mostrarForm">
    <form action="#" @submit.prevent="validaFormContacto" class="px-3">
      <div class="row mb-3 ">
        <label for="cnombre" class="col-sm-2 col-form-label">Nombre</label>
        <div class="col-sm-10">
          <input type="text" :class="{'form-control':true, 'border-danger': v$.datos.nombre.$error}" v-model.trim="v$.datos.nombre.$model" id="cnombre" placeholder="Pedro Perez">
        </div>
      </div>
      <div class="row mb-3">
        <label for="cemail" class="col-sm-2 col-form-label">Email</label>
        <div class="col-sm-10">
          <input type="text" :class="{'form-control':true, 'border-danger': v$.datos.correo.$error}" v-model.trim="v$.datos.correo.$model" id="cemail" placeholder="correo@ejemplo.com">
        </div>
      </div>
      <div class="row mb-3">
        <label for="cempresa" class="col-sm-2 col-form-label">Empresa</label>
        <div class="col-sm-10">
          <input type="text" :class="{'form-control':true, 'border-danger': v$.datos.empresa.$error}" v-model.trim="v$.datos.empresa.$model" id="cempresa" placeholder="Empresa">
        </div>
      </div>
      <div class="row mb-3">
        <label for="ctelefono" class="col-sm-2 col-form-label">Teléfono</label>
        <div class="col-sm-10">
          <input type="tel" :class="{'form-control':true, 'border-danger': v$.datos.telefono.$error}" v-model.trim="v$.datos.telefono.$model" id="ctelefono" placeholder="0123456789" minlength="10" maxlength="10">
        </div>
      </div>
      <div class="row mb-3">
        <label for="ctipodesc" class="col-sm-4 form-label">Probar producto</label>
        <div class="col-sm-8">
          <select id="ctipodesc" class="form-select" v-model="seleccionado" @change="cambiaSeleccion">
          <option v-for="option in opcionesSelect" :selected="option.id == seleccionado" 
                  :value="option.id" :key="option.id">{{option.value}}</option>
        </select>
        </div>
      </div>
      <div class="col-auto">
        <button type="submit" class="btn btn-success fw-bold" data-bs-target="#Modal">Enviar</button>
      </div>
    </form>      
  </div>
</template> 

<script>
import {redireccionar} from '../redireccion'
import useVuelidate from '@vuelidate/core'
import {required, minLength, numeric, email} from '@vuelidate/validators'
import { Modal } from 'bootstrap';

export default {
    name: 'FormDescarga',
    data(){
        return {
            v$: useVuelidate(),
            modal: null,
            opcionesSelect: [{id:1,value:'Sait ERP'},{id:2,value:'Sait Básico'},{id:3,value:'Sait Nómina'},
            {id:4, value:'Tienda en linea Sait'}, {id:5,value:'Telefonia IP'}, {id:6,value:'Respaldos Nube'}],
            seleccionado: this.seleccion,
            datos: {nombre:'', correo:'', empresa:'', telefono:'', producto: ''}
        }
    },
    mounted(){ this.modal = Modal.getOrCreateInstance(document.querySelector('#Modal')); 
     },
    validations(){
      return {
        datos: {
          nombre: {required, minLength: minLength(4)},
          correo: {required, email},
          empresa: {required, minLength: minLength(4)},
          telefono: {required, minLength: minLength(10), numeric}
        }
      }
    },
    props: {
      seleccion: {type: Number, required: true},
    },
    computed: {
      producto(){
        return this.opcionesSelect[this.seleccionado - 1].value;
      }
    },
    methods: {
        mostrarForm(){
          this.modal.show();
        },
        cambiaSeleccion(event){
            this.seleccionado = event.target.value;
        },
        validaFormContacto(){
            //validacion
            //procesar
            //redirigir
            this.v$.$validate();
            if (!this.v$.$error) {
              //this.modal.hide();
              this.procesarFormContacto();
            } else {
              return;
            }
            
        },
        async procesarFormContacto(){
          this.datos.producto = this.producto;
          const response = await fetch('/form.php', {//error
            method: 'POST',
            headers:{ 'Content-Type':'application/json' },
            body: JSON.stringify(this.datos),
            cache: 'no-cache',
            });
          if(response.status === 200){
            this.modal.hide();
            this.redirigir();
          }

          //else error, datos invalidados

          //pruebas localhost
          // const response = await fetch('/', {method: 'POST', headers:{ 'Content-Type':'application/json' }, body: JSON.stringify(this.datos)});
          // console.log(response.status);
          // this.redirigir();
        },
        redirigir(){
          redireccionar();
          this.$router.push(`/aviso-descarga?correo=${this.datos.correo}&producto=${this.datos.producto}`);
        }
    }
}
</script>
<style scoped>
.wForm{
  position: fixed;
  width: 30rem;
  z-index: 100;
  top: 35vh;
  left: calc(50vw - 15rem);
}
</style>